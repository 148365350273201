.login_body {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/images/background_login.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: row;
}

.login_view_login {
    height: 60vh;
    width: 50%;
    background-color: white;
    border-radius: 20px;
    animation-name: boxlogin;
    animation-duration: 3s;
    position: relative;
    box-shadow: 5px 10px 20px #AAAAAA;
    display: flex;
    flex-direction: row;
}

.login_view_left {
    padding: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
}

.login_image {
    height: 80%;
    width: 80%;
    object-fit: contain;
    
}

.login_view_right {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    border-left: 0.5px solid #EEEEEE;
    justify-content: center;
}

.login_text_login {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
}

.login_view_input {
    height: 50px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: rgba(141, 189, 255, 0.2);
    border-radius: 7px;
    padding-right: 10px;
}

.login_view_input input {
    border: 0px;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    padding-left: 7px;
    padding-right: 5px;
    font-size: 15px;
    background-color: rgba(141, 189, 255, 0);
}

.login_view_input input:focus {
    outline: none;
}

.button_login {
    height: 50px;
    width: 100%;
    background-color: #3b7dd8;
    border-radius: 5px;
    margin-top: 10px;
    border: none;
}

.button_login h1 {
    color: white;
    font-weight: bold;
    font-size: 15px;
}

.button_login:hover {
    box-shadow: 5px 10px 20px #3b7dd8;
}

@keyframes boxlogin {
    0% {
        bottom: 100px;
        top: 100px;
    }
    25% {
        bottom: 75px;
        top: 0px;
    }
    50% {
        bottom: 50px;
        top: 0px;
    }
    75% {
        bottom: 25px;
        top: 0px;
    }
    100% {
        bottom: 0px;
        top: 0px;
    }
}

@media only screen and (max-width: 320px) {
    .login_view_login {
        width: 90%;
    }
    .login_view_left {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .login_view_login {
        width: 90%;
    }
    .login_view_left {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .login_view_login {
        width: 80%;
    }
    .login_view_left {
        display: none;
    }
}

@media only screen and (max-width:800px) {
    .login_view_login {
        width: 70%;
    }
    .login_view_left {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .login_view_login {
        width: 80%;
    }
    .login_view_left {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .login_view_login {
        width: 80%;
    }
    .login_view_left {
        display: none;
    }
}

@media only screen and (min-width: 1025px) {
    .login_view_login {
        width: 50%;
    }
}