::-webkit-scrollbar {
    width: 2px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #DDDDDD;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #AAAAAA;
}


.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    width: 100px;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}
.view_content {
    background-color: #EEEEEE;
    flex-direction: column;
    display: flex;
    flex: 1;
    overflow: auto;
    padding: 20px;
    height: auto;
}

.content_item {
    background-color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    height: 77vh;
    box-shadow: 5px 7px 15px #AAAAAA;
}
/*css cu*/





.marginbottom {
    margin-bottom: 15px;
}

.content_left {
    flex: 7;
    background-color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    border-radius: 10px;
    height: 77vh;
    box-shadow: 5px 7px 15px #AAAAAA;
}

.content_right {
    flex: 3;
    background-color: white;
    padding: 15px 10px 15px 10px;
    border-radius: 10px;
    height: 77vh;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 7px 15px #AAAAAA;
}

.content_right_2 {
    flex: 1;
    background-color: white;
    padding: 15px 10px 15px 10px;
    height: 40vh;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 7px 15px #AAAAAA;
}

.overflow {
    overflow: auto;
}

.view_total {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
}

.item_total {
    height: 170px;
    background-color: red;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.item_total span {
    font-size: 17px;
    font-weight: bold;
}

.item_total h1 {
    font-size: 25px;
    font-weight: bold;
}

.title_content {
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 10px;
}

.title_content h1 {
    font-size: 15px;
    color: #181c32;
    font-weight: 600;
    font-size: 1.275rem;
    flex: 9;
}


.timeline_dot {
    height: 8px;
    width: 8px;
    border-radius: 5px;
    border: 1px solid gray;
    margin-left: -4px;
}

.content_timeline {
    height: auto;
    border-left: 1px solid #DDDDDD;
    padding: 10px;
    font-weight: 600;
    color: gray;
}

.view_item_timeline {
    display: flex;
    flex-direction: row;
}

.time {
    margin-top: -4px;
    margin-right: 15px;
    color: black;
    font-weight: 600;
}

.view_char {
    height: 43vh;
    width: 100%;
    padding-bottom: 10px;
}

.view_item_topproduct {
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 5px;
    padding-top: 5px;
    align-items: center;
}

.image_topproduct {
    height: 60px;
    width: 60px;
    border-radius: 5px;
}

.text_product {
    margin-left: 10px;
    width: 300px;
    display: flex;
}

.text_product span {
    font-size: 1.08rem;
    color: #3f4254;
    font-weight: 600;
    outline: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price_product {
    width: 100px;
    display: flex;
    justify-content: flex-end;
    font-size: 1.08rem;
    color: red;
    font-weight: 600;
    outline: 0;
    flex: 2;
}

@media only screen and (max-width: 320px) {
    .home_body {
        height: auto;
    }
    .view_right {
        height: auto;
    }
    .content_left {
        flex: 1;
        margin-bottom: 15px;
        margin-right: 0px;
    }
    .view_total {
        flex-direction: column;
    }
    .left {
        margin-left: 0px;
    }
    .content_right {
        flex: 1;
    }
    .content_item {
        flex-direction: column;
        margin-bottom: 10px;
        height: auto;

    }
}

@media only screen and (max-width: 480px) {
    .home_body {
        height: auto;
    }
    .view_right {
        height: auto;
    }
    .view_total {
        flex-direction: column;
    }
    .left {
        margin-left: 0px;
    }
    .content_left {
        flex: 1;
        margin-bottom: 15px;
        margin-right: 0px;
    }
    .content_right {
        flex: 1;
    }
    .content_item {
        flex-direction: column;
        margin-bottom: 10px;
        height: auto;
    }
}

@media only screen and (max-width: 600px) {
    
}

@media only screen and (max-width:800px) {
    
}

@media only screen and (max-width: 768px) {
    
}

@media only screen and (max-width: 1024px) {
    
}

@media only screen and (min-width: 1025px) {}